import React, { useEffect, useState } from "react";
import { fetchUser } from "./api";
import { useParams,Link } from "react-router-dom";


const ShowUser = () => {
  const { id } = useParams(); // Get ID from URL
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleShow = async () => {
      try {
        const response = await fetchUser(id);
        setUser(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user data");
        setLoading(false); // Stop loading state
      }
    };
    handleShow();
  }, [id]); // Dependency array added

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2>User Details <Link className="btn btn-primary float-end" to="/Userlistdetails">Back</Link></h2>
      {user ? (
        <div>
          <p><strong>ID:</strong> {user.id}</p>
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Phone:</strong> {user.mobile}</p>
        </div>
      ) : (
        <p>No user found.</p>
      )}
    </div>
  );
};

export default ShowUser;
