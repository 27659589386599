import React, { useState } from "react";
import { createUser } from "./api";

const UserForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        gender: "",
        hobbies: [],
        address: "",
        state: "",
        city: "",
        image: "",
    });
    const [errors, setErrors] = useState({}); // To store validation errors
    const [message, setMessage] = useState(null); // For success/error messages

    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState("");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            const updatedHobbies = checked
                ? [...formData.hobbies, value]
                : formData.hobbies.filter((hobby) => hobby !== value);
            setFormData({ ...formData, hobbies: updatedHobbies });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
        setFormData({ ...formData, image: e.target.files[0] });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors
        
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("mobile", formData.mobile);
        formDataToSend.append("gender", formData.gender);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("state", formData.state);
        formDataToSend.append("city", formData.city);
        
        // Append hobbies as an array
        formData.hobbies.forEach((hobby) => formDataToSend.append("hobbies[]", hobby));
    
        // Append the image file if available
        if (image) {
            formDataToSend.append("image", image);
        }

        createUser(formDataToSend)
            .then(() => {
                //alert("User added successfully!");
                setFormData({
                    name: "",
                    email: "",
                    mobile: "",
                    gender: "",
                    hobbies: [],
                    address: "",
                    state: "",
                    city: "",
                    image: "",
                });
                setMessage("User added successfully!");
                setErrors({}); // Clear any previous errors
                setImage(null);
                setPreview("");
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    // Laravel sends validation errors with 422 status
                    setErrors(error.response.data.errors);
                } else {
                    alert("An unexpected error occurred. Please try again.");
                }
            });
    };

    return (
        <>
        {message && <div className="alert alert-success">{message}</div>}
        <form onSubmit={handleSubmit}>
            <div class="form-group">
                <label>Name:</label>
                <input type="text" name="name" class="form-control" value={formData.name} onChange={handleChange}  />
                {errors.name && <p className="text-danger">{errors.name[0]}</p>}
            </div>
            <div class="form-group">
                <label>Email:</label>
                <input type="email" name="email" class="form-control" value={formData.email} onChange={handleChange}  />
                {errors.email && <p className="text-danger">{errors.email[0]}</p>}
            </div>
            <div class="form-group">
                <label>Mobile:</label>
                <input type="text" name="mobile" class="form-control" value={formData.mobile} onChange={handleChange}  />
                {errors.mobile && <p className="text-danger">{errors.mobile[0]}</p>}
            </div>
            <div class="form-group">
                <label>Gender:</label>
                <label>
                    <input type="radio" name="gender" value="Male" onChange={handleChange}  /> Male
                </label>
                <label>
                    <input type="radio" name="gender" value="Female" onChange={handleChange}  /> Female
                </label>
                {errors.gender && <p className="text-danger">{errors.gender[0]}</p>}
            </div>
            <div class="form-group">
                <label>Hobbies:</label>
                <label>
                    <input type="checkbox" value="Cricket" onChange={handleChange} /> Cricket
                </label>
                <label>
                    <input type="checkbox" value="Music" onChange={handleChange} /> Music
                </label>
                <label>
                    <input type="checkbox" value="Reading" onChange={handleChange} /> Reading
                </label>
                <label>
                    <input type="checkbox" value="Playing Games" onChange={handleChange} /> Playing Games
                </label>
                {errors.hobbies && <p className="text-danger">{errors.hobbies[0]}</p>}
            </div>
            <div class="form-group">
                <label>Address:</label>
                <textarea name="address" class="form-control" value={formData.address} onChange={handleChange} ></textarea>
                {errors.address && <p className="text-danger">{errors.address[0]}</p>}
            </div>
            <div class="form-group">
                <input type="file" name="image" onChange={handleFileChange} />
                {preview && <img src={preview} alt="Preview" style={{ width: 100, height: 100, marginTop: 10 }} />}
                
            </div>
            <div class="form-group">
                <label>State:</label>
                <select name="state" class="form-control" value={formData.state} onChange={handleChange} >
                    <option value="">Select State</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Maharashtra">Maharashtra</option>
                </select>
                {errors.state && <p className="text-danger">{errors.state[0]}</p>}
            </div>
            <div class="form-group">
                <label>City:</label>
                <select name="city" class="form-control" value={formData.city} onChange={handleChange}>
                    <option value="">Select City</option>
                    {formData.state === "Gujarat" && (
                        <>
                            <option value="Ahmedabad">Ahmedabad</option>
                            <option value="Surat">Surat</option>
                        </>
                    )}
                    {formData.state === "Maharashtra" && (
                        <>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Pune">Pune</option>
                        </>
                    )}
                </select>
                {errors.city && <p className="text-danger">{errors.city[0]}</p>}
            </div>
            <div class="form-group">
            <button type="submit" className="btn btn-success">Submit</button>
            </div>
        </form>
        </>
    );
};

export default UserForm;
