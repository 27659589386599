import axios from "axios";

export const API = axios.create({ baseURL: "http://127.0.0.1:8000/api" });

export const fetchUsers = () => API.get("/user-details");
export const createUser = (user) => API.post("/user-details", user, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
export const fetchUser = (id) => API.get(`/user-details/${id}`);
export const updateUser = (id, user) => API.post(`/user-details/${id}?_method=PUT`, user, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
});
export const deleteUser = (id) => API.delete(`/user-details/${id}`);


export const register = (data) => API.post("/register", data);
export const login = (data) => API.post("/login", data);
export const logout = (token) => API.post("/logout", {}, { headers: { Authorization: `Bearer ${token}` } });
export const getUser = (token) => API.get("/user", { headers: { Authorization: `Bearer ${token}` } });






