import logo from './logo.svg';
import './App.css';
import NavBar from './NavBar';
import Home from "./components/Home";
import About from "./components/About";
import UserDetails from "./components/UserDetails";
import UserListDetails from "./components/UserListDetails";
import UserListDatatable from "./components/UserDatatable";
import ShowUserDetails from "./components/ShowUserDetails";
import SearchingData from "./components/SearchingData";
import Tenderlist from "./components/Tenderlist";
import SingleTender from "./components/SingleTender";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { AuthProvider } from './AuthContext';


import Login from './Login';
import Register from './Register';

function App() {
  return (
    <AuthProvider>
    <Router>
      <NavBar/>
        <Routes>
            <Route path="/" element={<Home />} exact></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/userdetails" element={<UserDetails />}></Route>
            <Route path="/UserListDetails" element={<UserListDetails />}></Route>
            <Route path="/Userlistdattable" element={<UserListDatatable />}></Route>
            <Route path="/searchingdata" element={<SearchingData />}></Route>

            <Route path="/user/:id" element={<ShowUserDetails />}></Route>
            
            <Route path="/search/:keyword?" element={<Tenderlist />}></Route>   
            <Route path="/tender/:ourrefno" element={<SingleTender />}></Route>   

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

         </Routes> 
    </Router>
    </AuthProvider>
  );
}

export default App;
