import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate, useParams,Link} from 'react-router-dom';

const API_URL = 'https://marvel.nationaltenders.com/api/singletender'; // Replace with your backend API

const SingleTender = () => {
    const {ourrefno} = useParams();
    const [data, setData] = useState([]); // Stores API data
    const [loading, setLoading] = useState(false);
     useEffect(() => {
            fetchTenderdata();
        }, [ourrefno]);
    
    const fetchTenderdata = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_URL, {
                params: { ourrefno},
            });
            //console.log(response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
        setLoading(false);
    };
    return (
        <>
        <main className="main">
        <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
        <div className="container position-relative">
            <h1>Single Tender</h1>
            <p>Esse dolorum voluptatum ullam est sint nemo et est ipsa porro placeat quibusdam quia assumenda numquam molestias.</p>
            <nav className="breadcrumbs">
            <ol>
                <li><a href="index.html">Home</a></li>
                <li className="current">List</li>
            </ol>
            </nav>
        </div>
        </div>   
        <div className="EventPage PosFixList DashBoardWrap">
           
            <div className="FilterBoxContent">
            {loading && <p>Loading...</p>}
            {/* {!loading && data.length === 0 && <p>No data found.</p>} */}
            {!loading && ( 
            <div className="container-fluid">
                <div className='row'>
                <div className="col-lg-8 ps-0">
        <div className="card">  
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="p-2 bg-primary text-white">Tendering Authority : {data.org_name}</h3>
                <table className="table">
                  <tbody><tr>
                    <td><strong>NTID</strong></td>
                    <td>{data.ourrefno}</td>
                  </tr>
                  <tr>
                    <td><strong>Organisation Name</strong></td>
                    <td>{data.org_name}</td>
                  </tr>
                  <tr>
                    <td><strong>Tender Brief</strong></td>
                    <td>{data.Work}</td>
                  </tr>
                  <tr>
                    <td><strong>Last Date For Submission</strong></td>
                    <td>{data.submitdate}</td>
                  </tr>
                  <tr>
                    <td><strong>Opening Date</strong></td>
                    <td>
                                            10 Mar, 2025
                                          </td>
                  </tr>
                </tbody></table>
              </div>
              <div className="col-lg-12">
                <h3 className="p-2 bg-primary text-white">Cost</h3>
                <table className="table">
                  <tbody><tr>
                    <td><strong>Document Cost</strong></td>
                    <td>{data.doccost == 0 ? 'Refer to document' : data.doccost}</td>
                  </tr>
                  <tr>
                    <td><strong>EMD</strong></td>
                    <td>{data.earnestamount == 0 ? 'Refer to document' : data.earnestamount}</td>
                  </tr>
                  <tr>
                    <td><strong>Estimated Cost</strong></td>
                    <td>{data.tenderamount == 0 ? 'Refer to document' : data.tenderamount}</td>
                  </tr>
                </tbody></table>
              </div>
              <div className="col-lg-12">
                <h3 className="p-2 bg-primary text-white">Location</h3>
                <table className="table">
                  <tbody><tr>
                    <td><strong>City</strong></td>
                    <td>{data.city}</td>
                  </tr>
                  <tr>
                    <td><strong>State</strong></td>
                    <td>{data.state_name}</td>
                  </tr>
                </tbody></table>
              </div>
              <div className="col-lg-12">
                <h3 className="p-2 bg-primary text-white">Download 
                Documents</h3>
                              </div>
                              <div className="col-12">
                  <div className="d-flex justify-content-center align-items-center">
                    <button className="btn-doc" type="button" disabled="">Download Document</button>
                    <div className="position-absolute text-center">
                      <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#inquirymodal" className="text-center text-white"><b>Register to see full details</b></a>
                    </div>
                  </div>
                </div>
                          </div>
          </div>
        </div>
      </div>

                </div>
                  
            </div>  
            )}
        </div>
        
        </div>
        </main>
        </>
    );
};
export default SingleTender;