import React, { useEffect, useState } from "react";
import { fetchUsers, fetchUser, updateUser, deleteUser } from "./api";
import {Link} from 'react-router-dom';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({}); // To store validation errors
    const [selectedUser, setSelectedUser] = useState(null); // For showing user details
    const [editUser, setEditUser] = useState(null); // For editing user
    const [message, setMessage] = useState(null); // Success message

    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState("");

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        try {
            const response = await fetchUsers();
            //console.log(response.data);
            setUsers(response.data);
        } catch (err) {
            //setError("Failed to fetch users.");
            setErrors({});
        }
    };

    const handleShow = async (id) => {
        try {
            const response = await fetchUser(id);
            setSelectedUser(response.data);
            setMessage(null); // Clear previous success messages
        } catch (err) {
            //setError("Failed to fetch user details.");
            setErrors({});
        }
    };

    const handleEdit = async (id) => {
        try {
            const user = await fetchUser(id);
            setEditUser(user.data);
            setErrors({});
            setMessage(null); // Clear previous success messages
            setImage(user.data.image);
            if(user.data.image != ""){
                setPreview("http://127.0.0.1:8000/uploads/"+user.data.image);
            }
        } catch (err) {
            //setError("Failed to fetch user details for editing.");
            setErrors({});
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteUser(id);
            setMessage("User deleted successfully!");
            loadUsers(); // Refresh user list
        } catch (err) {
            //setError("Failed to delete user.");
            setErrors({});
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        
        const formDataToSend = new FormData();
        formDataToSend.append("name", editUser.name);
        formDataToSend.append("email", editUser.email);
        formDataToSend.append("mobile", editUser.mobile);
        formDataToSend.append("gender", editUser.gender);
        formDataToSend.append("address", editUser.address);
        formDataToSend.append("state", editUser.state);
        formDataToSend.append("city", editUser.city);
        
        // Append hobbies as an array
        editUser.hobbies.forEach((hobby) => formDataToSend.append("hobbies[]", hobby));
    
        // Append the image file if available
        if (image instanceof File) {
            formDataToSend.append("image", image);
        } else {
            formDataToSend.append("image", ""); // Ensures Laravel doesn't enforce 'required' rule
        }
        try {
            await updateUser(editUser.id, formDataToSend);
            setMessage("User updated successfully!");
            setEditUser(null); // Close edit form
            setErrors({});
            loadUsers(); // Refresh user list
        } catch (err) {
            //setError("Failed to update user.");
            if (err.response && err.response.status === 422) {
                // Laravel sends validation errors with 422 status
                setErrors(err.response.data.errors);
            } else {
                alert("An unexpected error occurred. Please try again.");
            }
        }
    };
    const radioChange = (e) => {
    console.log(e.target.value);
    }
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setEditUser({ ...editUser, [name]: value });
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            const updatedHobbies = checked
                ? [...editUser.hobbies, value]
                : editUser.hobbies.filter((hobby) => hobby !== value);
                setEditUser({ ...editUser, hobbies: updatedHobbies });
        } else {
                setEditUser({ ...editUser, [name]: value });
        }
    };
    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
        setEditUser({ ...editUser, image: e.target.files[0] });
    };
    return (
        <div className="row">
            <div className="col-md-12">
            
                
            <h1>User List</h1>
            
            <Link className="btn btn-primary float-end" to="/userdetails">Create User</Link>
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
              
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>City</th>
                        <th>hobbies</th>
                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                          // imgurl = "http://127.0.0.1:8000/uploads/"+user.image;
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.gender}</td>
                            <td>{user.address}</td>
                            <td>{user.state}</td>
                            <td>{user.city}</td>
                            <td>{Array.isArray(user.hobbies) ? user.hobbies.join(", ") : user.hobbies}</td>
                            <td>{user.image && (
                               
                               <img src={user.image_url	} alt="User" width="100" />
                            )}</td>
                            <td>
                            <Link className="btn btn-warning m-2" to={`/user/${user.id}`}>Show Page</Link>
                                <button className="btn btn-primary" onClick={() => handleShow(user.id)}>Show</button>
                                <button className="btn btn-info m-2" onClick={() => handleEdit(user.id)}>Edit</button>
                                <button className="btn btn-danger" onClick={() => handleDelete(user.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Show User Details */}
            {selectedUser && (
                <div>
                    <h2>User Details</h2>
                    <p>Name: {selectedUser.name}</p>
                    <p>Email: {selectedUser.email}</p>
                    <p>Mobile: {selectedUser.mobile}</p>
                    <button onClick={() => setSelectedUser(null)}>Close</button>
                </div>
            )}

            {/* Edit User Form */}
            {editUser && (
                <div>
                    <h2>Edit User</h2>
                    <form onSubmit={handleUpdate}>
                    
                        <div class="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                class="form-control"
                                value={editUser.name}
                                onChange={handleChange}
                                required
                            />
                            {errors.name && <p className="text-danger">{errors.name[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                class="form-control"
                                value={editUser.email}
                                onChange={handleChange}
                                required
                            />
                            {errors.email && <p className="text-danger">{errors.email[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>Mobile:</label>
                            <input
                                type="text"
                                name="mobile"
                                class="form-control"
                                value={editUser.mobile}
                                onChange={handleChange}
                                required
                            />
                            {errors.mobile && <p className="text-danger">{errors.mobile[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>Gender:</label>
                            <label>
                                <input type="radio" name="gender" value="Male" checked={editUser.gender ==="Male"} onChange={handleChange}  /> Male
                            </label>
                            <label>
                                <input type="radio" name="gender" value="Female" checked={editUser.gender ==="Female"} onChange={handleChange}  /> Female
                            </label>
                            {errors.gender && <p className="text-danger">{errors.gender[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>Hobbies:</label>
                            <label>
                                <input type="checkbox" value="Cricket" 
                                checked={editUser.hobbies?.includes("Cricket")} 
                                onChange={handleChange} /> Cricket
                            </label>
                            <label>
                                <input type="checkbox" value="Music"
                                checked={editUser.hobbies?.includes("Music")} 
                                 onChange={handleChange} /> Music
                            </label>
                            <label>
                                <input type="checkbox" value="Reading" 
                                checked={editUser.hobbies?.includes("Reading")} 
                                onChange={handleChange} /> Reading
                            </label>
                            <label>
                                <input type="checkbox" value="Playing Games"
                                checked={editUser.hobbies?.includes("Playing Games")} 
                                onChange={handleChange} /> Playing Games
                            </label>
                            {errors.hobbies && <p className="text-danger">{errors.hobbies[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>Address:</label>
                            <textarea name="address" class="form-control" value={editUser.address} onChange={handleChange} ></textarea>
                            {errors.address && <p className="text-danger">{errors.address[0]}</p>}
                        </div>
                        <div class="form-group">
                            <input type="file" name="image" onChange={handleFileChange} />
                            {preview && <img src={preview} alt="Preview" style={{ width: 100, height: 100, marginTop: 10 }} />}
                            
                        </div>
                        <div class="form-group">
                            <label>State:</label>
                            <select name="state" class="form-control" value={editUser.state} onChange={handleChange} >
                                <option value="">Select State</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Maharashtra">Maharashtra</option>
                            </select>
                            {errors.state && <p className="text-danger">{errors.state[0]}</p>}
                        </div>
                        <div class="form-group">
                            <label>City:</label>
                            <select name="city" class="form-control" value={editUser.city} onChange={handleChange}>
                                <option value="">Select City</option>
                                {editUser.state === "Gujarat" && (
                                    <>
                                        <option value="Ahmedabad">Ahmedabad</option>
                                        <option value="Surat">Surat</option>
                                    </>
                                )}
                                {editUser.state === "Maharashtra" && (
                                    <>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Pune">Pune</option>
                                    </>
                                )}
                            </select>
                            {errors.city && <p className="text-danger">{errors.city[0]}</p>}
                        </div>
                        <button type="submit">Update</button>
                        <button onClick={() => setEditUser(null)}>Cancel</button>
                    </form>
                </div>
            )}
            </div>
        </div>
    );
};

export default UserList;