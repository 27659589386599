import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

const API_URL = 'http://127.0.0.1:8000/api/searchusers'; // Replace with your backend API

const SearchingData = () => {
    const [filters, setFilters] = useState({
    search: '',
    role: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    });

    const [data, setData] = useState([]); // Stores API data
    const [search, setSearch] = useState(''); // Search input value
    const [hasMore, setHasMore] = useState(true); // Track if more records exist

    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null); // Success message
    const [errors, setErrors] = useState({}); // To store validation errors
    
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');

    const [lastPage, setLastPage] = useState(1);

    const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setPage(1); // Reset to first page when filters change
    setData([]); // Reset data when filtering
    setHasMore(true); // Reset "No More Records" flag
    };


    useEffect(() => {
        // Fetch API data
        fetchUsers(false);
      }, [filters, page, sortColumn, sortOrder]);

      
      const fetchUsers = async (append = false) => {
        setLoading(true);
        try {
          const response = await axios.get(API_URL, {
            params: { page, sortBy: sortColumn, order: sortOrder,...filters }
          });
          setData(response.data.data);
          /*if (!append) {
                setData(response.data.data);
            } else {
                if(response.data.data != ""){
                    setData(prevData => [...prevData, ...response.data.data]);
                }   
            }*/
          setTotalRows(response.data.total);
          setLastPage(response.data.last_page);
           // Check if there are more records to load
            /*if (data.length + response.data.data.length >= response.data.total) {
                setHasMore(false);
            }*/
          
        } catch (error) {
          console.error('Error fetching users:', error);
        }
        setLoading(false);
      };
      /*const Viewmore = async () => {
        setPage(page + 1);
        try {
          const response = await axios.get(API_URL, {
            params: { page, sortBy: sortColumn, order: sortOrder,...filters }
          });
          setData(response.data.data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };*/
      const loadMore = () => {
            if (hasMore) {
                setPage(prevPage => prevPage + 1);
                fetchUsers(true); // Append new records
            }
        };
          

    return (
        <>
         <main className='main'>
        
        <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
        <div className="container position-relative">
            <h1>List Data</h1>
            <p>Esse dolorum voluptatum ullam est sint nemo et est ipsa porro placeat quibusdam quia assumenda numquam molestias.</p>
            <nav className="breadcrumbs">
            <ol>
                <li><a href="index.html">Home</a></li>
                <li className="current">List</li>
            </ol>
            </nav>
        </div>
        </div>
        <section id="about" className="about section">
        <div className="container">
        <div className="row gy-4">
        {/* <UserDatatable></UserDatatable> */}

                <div className="row">
                    <div className="col-md-12">  
                    <input type="text" name="search" placeholder="Search" onChange={handleFilterChange} />
                    <select name="role" onChange={handleFilterChange}>
                    <option value="">All Roles</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    </select>
                    <select name="status" onChange={handleFilterChange}>
                    <option value="">All Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    </select>
                    <input type="date" name="dateFrom" onChange={handleFilterChange} />
                    <input type="date" name="dateTo" onChange={handleFilterChange} />   
                    </div>
                    <div className="col-md-12">     
                    <h1>Data List : Total {totalRows}</h1>
                    

                    {/* User List */}
                    <div className="border rounded-lg p-4">
                        {loading && <p>Loading...</p>}
                        {!loading && data.length === 0 && <p>No users found.</p>}
                        {!loading && data.length > 0 && (
                            <ul>
                                {data.map(user => (
                                    <li key={user.id} className="border-b p-2">
                                        <strong>{user.id} - {user.name}</strong> - {user.email}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                   
                       
                    </div>
                    <div className='col-md-12'>
                        {/* View More Button 
                        {hasMore ? (
                            <div className="text-center mt-4">
                                <button onClick={loadMore} className="btn btn-primary text-white px-4 py-2 rounded">
                                    View More
                                </button>
                            </div>
                        ) : (
                            <p className="text-center mt-4 text-gray-500">No more records</p>
                        )}*/}
                        {/* <button className='btn btn-primary' onClick={Viewmore}>View more</button> */}

                        {/* Pagination */}
                        <button className='btn btn-primary' disabled={page === 1} onClick={() => setPage(page - 1)}>Previous</button>
                        <span> Page {page} of {lastPage} </span>
                        <button className='btn btn-primary' disabled={page === lastPage} onClick={() => setPage(page + 1)}>Next</button>
   
                    </div> 
                </div> 

        </div>
        </div>

        </section>
            

        </main>
        </>
    );
};
export default SearchingData;