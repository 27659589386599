import React, { useState, useEffect } from 'react';
//import UserDatatable from '../UserDatatable';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import {Link} from 'react-router-dom';

const API_URL = 'http://127.0.0.1:8000/api/users'; // Replace with your backend API

const UserListDatatable = () => {
    
    const [data, setData] = useState([]); // Stores API data
    const [search, setSearch] = useState(''); // Search input value

    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null); // Success message
    const [errors, setErrors] = useState({}); // To store validation errors
    
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [perPage, setPerPage] = useState(10);

    //const [searchname, setSearchname] = useState('');
    // Add filter states
    const [filters, setFilters] = useState({
      input1: "",
      input2: "",
    }); 

    useEffect(() => {
        // Fetch API data
        fetchUsers();
      }, [page, perPage, search, sortColumn, sortOrder]);

      
      const fetchUsers = async () => {
        setLoading(true);
        try {
          const response = await axios.get(API_URL, {
            params: { page, perPage, search, sortBy: sortColumn, 
              order: sortOrder,
              ...filters, // Add filters to API request
             }
          });
          setData(response.data.data);
          setTotalRows(response.data.total);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
        setLoading(false);
      };

        // Handle sorting
        const handleSort = (column, sortDirection) => {
            //console.log(column.selector);
            setSortColumn(column.sortField);
            setSortOrder(sortDirection);
        };
     
          
          // Handle filter changes
        // const handleFilterChange = (e) => {
        //     const { name, value } = e.target;
        //     setFilters((prevFilters) => ({
        //         ...prevFilters,
        //         [name]: value, // Ensure input updates state correctly
        //     }));
        // };
       // Define columns for DataTable
        const columns = [
            { name: 'ID', selector: row => row?.id, sortable: true, sortField: 'id' },
            { name: 'Name', selector: row => row?.name || '', sortable: true, sortField: 'name' },
            { name: 'Email', selector: row => row?.email || '', sortable: true, sortField: 'email' },
            { name: 'Mobile', selector: row => row?.mobile || '', sortable: true, sortField: 'mobile' },
            {
              name: 'Image',
              selector: row => row?.image || '', // Ensure image exists
              cell: row => (
                  row.image ? (
                      <img 
                          src={`http://127.0.0.1:8000/uploads/${row.image}`} 
                          alt="User" 
                          width="50" 
                          height="50" 
                          style={{ borderRadius: '5px' }} 
                      />
                  ) : (
                      <span>No Image</span>
                  )
              ),
              ignoreRowClick: true, // Prevent sorting when clicking the image
              allowOverflow: true,
              button: true
          }
        ];
        
        
    return (
        <>
         <main className='main'>
        
        <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
        <div className="container position-relative">
            <h1>UserList Datatable</h1>
            <p>Esse dolorum voluptatum ullam est sint nemo et est ipsa porro placeat quibusdam quia assumenda numquam molestias.</p>
            <nav className="breadcrumbs">
            <ol>
                <li><a href="index.html">Home</a></li>
                <li className="current">About</li>
            </ol>
            </nav>
        </div>
        </div>
        <section id="about" className="about section">
        <div className="container">
        <div className="row gy-4">
        {/* <UserDatatable></UserDatatable> */}

                <div className="row">
                    <div className="col-md-12">     
                    <h1>User List</h1>
                    <Link className="btn btn-primary float-end" to="/userdetails">Create User</Link>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {message && <div className="alert alert-success">{message}</div>}

                     {/*  Filter Inputs 
                    <input
                        type="text"
                    
                        placeholder="Filter by Name"
                        value={filters.input1}
                        onChange={handleFilterChange}
                        //onChange={(e) => setFilters({ input1: e.target.value, input2: "" })}
                    />
                    
                    <button onClick={() => setFilters({ input1: "", input2: "" })}>
                    Clear Filters
                    </button>*/}

                    {/* <input
                        type="text"
                        placeholder="Search by Name..."
                        value={searchname}
                        onChange={(e) => setSearchname(e.target.value)}
                        style={{ marginBottom: '10px', padding: '5px', width: '300px' }}
                    /> */}

                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by Name or Email..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{ marginBottom: '10px', padding: '5px', width: '300px' }}
                    />

                    {/* DataTable */}
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={setPage}
                        onChangeRowsPerPage={setPerPage}
                        onSort={handleSort}
                        sortServer
                        highlightOnHover
                        striped
                    />

                    </div>
                </div> 

        </div>
        </div>

        </section>
            

        </main>
        </>
    );
};
export default UserListDatatable;