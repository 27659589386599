import {React,useContext} from 'react';
import {Link,useNavigate } from 'react-router-dom';
import { logout } from "./api";
import { AuthProvider,AuthContext } from './AuthContext';


const NavBar = () => {
    const { user, token, logout: handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    //console.log(user.name);
    const handleClick = async () => {
        if (token) {
        await logout(token);
        handleLogout();
        navigate("/login");
        }
    };
    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl position-relative d-flex align-items-center">

            <a href="index.html" className="logo d-flex align-items-center me-auto">
                <h1 className="sitename">Logis</h1>
            </a>

            <nav id="navmenu" className="navmenu">
                <ul>
                <li><Link to="/" className="active">Home<br/></Link></li>
                <li><Link to="/about">About</Link></li>
                
                <li><Link to="/Userlistdetails">UserListDetails</Link></li>

                <li><Link to="/Userlistdattable">UserlistDattable</Link></li>

                <li><Link to="/searchingdata">SearchingData</Link></li>

                <li><Link to="/search">Tenders</Link></li>
                
                {!token ? (
                    <>
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                    </>
                ) : (
                    <button onClick={handleClick}>Logout</button>
                )}

                {/* <li><Link to="/login">Login</Link></li>
                <li><Link to="/register">Register</Link></li> */}
                    
                <li><a href="services.html">Services</a></li>
                <li><a href="pricing.html">Pricing</a></li>
                <li className="dropdown"><a href="#"><span>Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                    <ul>
                    <li><a href="#">Dropdown 1</a></li>
                    <li className="dropdown"><a href="#"><span>Deep Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                        <ul>
                        <li><a href="#">Deep Dropdown 1</a></li>
                        <li><a href="#">Deep Dropdown 2</a></li>
                        <li><a href="#">Deep Dropdown 3</a></li>
                        <li><a href="#">Deep Dropdown 4</a></li>
                        <li><a href="#">Deep Dropdown 5</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Dropdown 2</a></li>
                    <li><a href="#">Dropdown 3</a></li>
                    <li><a href="#">Dropdown 4</a></li>
                    </ul>
                </li>
                <li><a href="contact.html">Contact</a></li>
                </ul>
                <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>

            <a className="btn-getstarted" href="get-a-quote.html">Get a Quote</a>

            </div>
        </header>
    );
};

export default NavBar;