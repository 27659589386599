import React, { useEffect, useState, useContext } from 'react';
import { login } from "./api";
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { loginUser, token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate("/");
        }
      }, [token]); // Dependency array added

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await login({ email, password });
          loginUser(res.data.token);
          navigate("/");
        } catch (error) {
          alert("Login failed");
        }
      };

    return (
        <main className='main'>
        
            <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
            <div className="container position-relative">
                <h1>Login</h1>
                <p>Login page</p>
                <nav className="breadcrumbs">
                <ol>
                    <li><a href="index.html">Home</a></li>
                    <li className="current">login</li>
                </ol>
                </nav>
            </div>
            </div>

            <section id="about" className="about section">

      <div className="container">

        <div className="row gy-4">
        <form onSubmit={handleSubmit}>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <button type="submit">Login</button>
        </form>
          
          

        </div>

      </div>

    </section>

    
        </main>
        
        
    );
};

export default Login;
