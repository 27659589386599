import React from 'react';
import ShowUser from '../ShowUser';

const ShowUserDetails = () => {
    
    return (
        <>
         <main className='main'>
        
        <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
        <div className="container position-relative">
            <h1>Create User Details</h1>
            <p>Esse dolorum voluptatum ullam est sint nemo et est ipsa porro placeat quibusdam quia assumenda numquam molestias.</p>
            <nav className="breadcrumbs">
            <ol>
                <li><a href="index.html">Home</a></li>
                <li className="current">About</li>
            </ol>
            </nav>
        </div>
        </div>
        <section id="about" className="about section">
        <div className="container">
        <div className="row gy-4">
        <ShowUser></ShowUser>
        </div>
        </div>

        </section>
            

        </main>
        </>
    );
};
export default ShowUserDetails;