import React, { useEffect, useState, useContext } from 'react';
import { register } from "./api";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {  token } = useContext(AuthContext);
    const navigate = useNavigate();

     useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]); // Dependency array added

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await register({ name, email, password });
          alert("Registered successfully! Please login.");
          navigate("/login");
        } catch (error) {
          alert("Registration failed");
        }
      };

    return (

        <main className='main'>
        
            <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
            <div className="container position-relative">
                <h1>Register</h1>
                <p>Register page</p>
                <nav className="breadcrumbs">
                <ol>
                    <li><a href="index.html">Home</a></li>
                    <li className="current">Register</li>
                </ol>
                </nav>
            </div>
            </div>

            <section id="about" className="about section">

      <div className="container">

        <div className="row gy-4">
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <button type="submit">Register</button>
        </form>
        </div>

      </div>

    </section>

    
        </main>
        
    );
};

export default Register;
