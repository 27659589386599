import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate, useParams,Link} from 'react-router-dom';

const API_URL = 'https://marvel.nationaltenders.com/api/searchtenders'; // Replace with your backend API

const Tenderlist = () => {
    const navigate = useNavigate();
    const { keyword } = useParams();
    
    const [filters, setFilters] = useState({
        keyword: keyword || '',
        role: '',
        status: '',
        dateFrom: '',
        dateTo: '',
    });

    const [data, setData] = useState([]); // Stores API data
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState('ourrefno');
    const [sortOrder, setSortOrder] = useState('asc');
    const [lastPage, setLastPage] = useState(1);
    const [tendertype, setTendertype] = useState('live');

    // useEffect(() => {
    //     //setFilters((prevFilters) => ({ ...prevFilters, keyword }));
    // }, [keyword]);

    useEffect(() => {
        fetchUsers();
    }, [filters, page, sortColumn, sortOrder]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_URL, {
                params: { page, sortBy: sortColumn, order: sortOrder, type: tendertype, ...filters },
            });
            //console.log(response.data.data);
            setData(response.data.data);
            setTotalRows(response.data.total);
            setLastPage(response.data.last_page);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
        setLoading(false);
    };

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
        setPage(1); // Reset to first page when filters change
    };

    const calculateDifference = (end) => {
    const d1 = new Date();
    const d2 = new Date(end);
    if(d1 == d2){
        return 'Ending Today';
    }else if(d2 > d1){
        const diffTime = Math.abs(d2 - d1);
        const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        return days +' days left';
    }else{
        return '';
    }
    };
    // Function to highlight matched keyword
    const highlightMatch = (text) => {
        if (!filters.keyword) return text;
        const regex = new RegExp(`(${filters.keyword})`, 'gi'); // Case-insensitive match
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === filters.keyword.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span>
            ) : (
                part
            )
        );
    };
    return (
        <>  
         <main className='main'> 
        <div className="page-title dark-background aos-init aos-animate" data-aos="fade" >
        <div className="container position-relative">
            <h1>Tender List Data</h1>
            <p>Esse dolorum voluptatum ullam est sint nemo et est ipsa porro placeat quibusdam quia assumenda numquam molestias.</p>
            <nav className="breadcrumbs">
            <ol>
                <li><a href="index.html">Home</a></li>
                <li className="current">List</li>
            </ol>
            </nav>
        </div>
        </div>
        <section id="about" className="about section">
        <div className="container">
        <div className="row gy-4">
                <div className="row">
                    <div className="col-md-12">  
                    <input type="text" name="keyword" placeholder="Search" value={filters.keyword}  onChange={handleFilterChange} />
                    <select name="type" onChange={handleFilterChange}>
                    <option value="live">live</option>
                    <option value="fresh">Fresh</option>
                    <option value="archive">Archive</option>
                    </select>
                    <select name="status" onChange={handleFilterChange}>
                    <option value="">All Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    </select>
                    <input type="date" name="dateFrom" onChange={handleFilterChange} />
                    <input type="date" name="dateTo" onChange={handleFilterChange} />   
                    </div>
                    <div className="col-md-12">     
                    <h1>Data List : Total {totalRows}</h1>
                    {/* User List */}
                   
                        {loading && <p>Loading...</p>}
                        {!loading && data.length === 0 && <p>No data found.</p>}
                        {!loading && data.length > 0 && (
                            <div id="lev1">  
                                {data.map(tender => (
                                    <div className="col-12">
                                    <div className="card mb-2 shadow">
                                      <div className="card-body">
                                         <div className="d-md-flex justify-content-between">
                                            <p className="mb-2 org_main">
                                               <span className="pe-2">
                                               <i className="fa  fa-building me-2"></i>{tender.org_name}</span>
                                               <span className="ps-2 date_status"><span>
                                                { calculateDifference(tender.submitdate) } 
                                                </span></span>
                                            </p>
                                            <p className="tenderid">NTID {tender.ourrefno}</p>
                                         </div>
                                         <div className="d-md-flex justify-content-between py-2">
                                            <p className="desc">{highlightMatch(tender.Work)}</p>
                                         </div>
                                         <div className="d-md-flex justify-content-between align-items-center">
                                            <p className="mb-2 org_main">
                                               <span className="pe-2 location"><i className="fa fa-map-marker"></i> {tender.city}, {tender.state_name}, India</span>
                                               <span className="pe-2 ps-2 border-left"><i className="fa fa-calendar"></i> End Date: <span className="text-danger">{tender.submitdate}</span></span>
                                               <span className="ps-2 pe-2 date_status"><i className="fa fa-inr"></i> {tender.tenderamount}</span>
                                            </p>
                                            <p className="view_link">
                                            <Link to={`/tender/${tender.ourrefno}`} target="_blank" className="btn btn-md btn-primary rounded-pill btn-hover-bg me-1" title="Tender view">
                                            <i className="fa-solid fa-eye"></i>
                                            </Link>
                                                    
                                             
                                            </p>
                                         </div>
                                         <Link className="stretched-link" target="_blank" to={`/tender/${tender.ourrefno}`}></Link>

                                        
                                      </div>
                                    </div>
                                  </div>

                                    
                                ))}
                            </div>
                        )}
                     
                    </div>
                    <div className='col-md-12'>
                        
                        {/* Pagination */}
                        <button className='btn btn-primary' disabled={page === 1} onClick={() => setPage(page - 1)}>Previous</button>
                        <span> Page {page} of {lastPage} </span>
                        <button className='btn btn-primary' disabled={page === lastPage} onClick={() => setPage(page + 1)}>Next</button>
   
                    </div> 
                </div> 
        </div>
        </div>
        </section>
        </main>
        </>
    );
};
export default Tenderlist;